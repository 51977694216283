import React from "react";
import PropTypes from "prop-types";
import isParent from "helpers/is-parent";
import LocationSearch from "services/location-search";

export default class LocationInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waitingForGoogle: true,
      inputValue: "",
      options: [],
      activeOption: -1,
      active: false,
    };
    this.config = {
      country: "UK",
    };
    this.searchTimeout = null;
    this.LocationSearch = undefined;
  }

  componentDidMount() {
    this.maybeInitialize();
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    if (value) {
      this.setState({ inputValue: value });
    } else if (value === "") {
      this.setState({ inputValue: value, activeOption: -1, options: [] });
    }
  }

  handleInputChange(value) {
    clearTimeout(this.searchTimeout);
    this.setState({ inputValue: value, active: true }, () => {
      this.props.onInputChange(value);
      if (value) {
        this.searchTimeout = setTimeout(() => {
          this.LocationSearch.search(value)
            .then((results) => this.setState({ options: results }))
            .catch((error) => {
              throw new Error(error);
            });
        }, 1);
      } else {
        this.setState({ options: [] });
      }
    });
  }

  handleKeyDown(e) {
    switch (e.which) {
      case 13: {
        // enter
        e.preventDefault();
        if (this.state.activeOption >= 0) {
          this.selectSuggestion(this.state.activeOption);
        }
        break;
      }
      case 38: {
        // arrow up
        e.preventDefault();
        if (this.state.activeOption === -1 || this.state.activeOption === 0) {
          this.setState({ activeOption: this.state.options.length - 1 });
        } else {
          this.setState({ activeOption: this.state.activeOption - 1 });
        }
        break;
      }
      case 40: {
        // arrow down
        e.preventDefault();
        if (this.state.activeOption === this.state.options.length - 1) {
          this.setState({ activeOption: 0 });
        } else {
          this.setState({ activeOption: this.state.activeOption + 1 });
        }
        break;
      }
      case 27: {
        this.setState({ active: false });
        this.input.blur();
        break;
      }
      default: {
        break;
      }
    }
  }

  handleInputBlur() {
    setTimeout(() => {
      const element = document.activeElement;
      let isOutside = true;

      if (element === this.container) {
        isOutside = false;
      }

      if (isOutside) {
        isOutside = !isParent(element, this.container);
      }

      if (isOutside) {
        this.setState(
          {
            active: false,
          },
          () => {
            if (!this.state.inputValue) {
              this.props.onChange(null);
            }
          },
        );
      }
    }, 300); // timeout necessary to allow the new focused element to gain focus
  }

  maybeInitialize() {
    const { google } = window;
    if (google === undefined) {
      setTimeout(() => this.maybeInitialize(), 100);
    } else {
      this.setState({ waitingForGoogle: false }, () => {
        const { defaultValue } = this.props;
        this.LocationSearch = new LocationSearch();
        if (defaultValue && !this.state.initializedWithValue) {
          this.LocationSearch.search(defaultValue)
            .then((results) => {
              this.setState({ options: results }, () => {
                this.setState({ initializedWithValue: true });
                this.selectSuggestion(0);
                this.input.blur();
              });
            })
            .catch((error) => {
              throw new Error(error);
            });
        }
      });
    }
  }

  selectSuggestion(index) {
    const value = this.state.options[index].displayAddress;
    this.setState(
      {
        inputValue: value,
        active: false,
        activeOption: -1,
      },
      () => {
        this.props.onInputChange(value);
        this.props.onChange(this.state.options[index]);
      },
    );
  }

  render() {
    const {
      className,
      inputClassName,
      placeholder,
      disabled,
      defaultValue,
      onChange,
      onInputChange,
      ...props
    } = this.props;
    const classNames = ["location-input", className];
    const { options } = this.state;
    if (this.props.disabled) {
      classNames.push("disabled");
    }
    if (this.state.active) {
      classNames.push("active");
    }
    return (
      <div
        className={classNames.join(" ")}
        ref={(domNode) => {
          this.container = domNode;
        }}
      >
        <input
          {...props}
          className={`location-input__input ${inputClassName}`}
          type="text"
          ref={(domNode) => {
            this.input = domNode;
          }}
          value={this.state.inputValue}
          onFocus={(e) => {
            this.setState({ active: true, activeOption: -1 });
            this.props.onFocus(e);
          }}
          onBlur={(e) => {
            this.handleInputBlur();
            this.props.onBlur(e);
          }}
          onKeyDown={(e) => this.handleKeyDown(e)}
          onChange={(e) => this.handleInputChange(e.target.value)}
          placeholder={this.state.waitingForGoogle ? "Loading..." : placeholder}
          disabled={disabled || this.state.waitingForGoogle}
        />
        <ul className="location-input__list" role="listbox">
          {options.length > 0 &&
            options.map((option, index) => (
              <li
                role="option"
                tabIndex="0"
                onFocus={() => this.setState({ activeOption: index })}
                onBlur={() => this.handleInputBlur()}
                aria-selected={
                  index === this.state.activeOption ? "true" : "false"
                }
                key={option.placeID}
                onClick={() => this.selectSuggestion(index)}
                className={`location-input__list-item${
                  index === this.state.activeOption ? " active" : ""
                }`}
              >
                {option.displayAddress}
              </li>
            ))}
          {options.length === 0 && (
            <li className="location-input__list-item no-results">
              {this.state.inputValue
                ? "No results."
                : "Start typing to show suggestions, then make a selection."}
            </li>
          )}
        </ul>
      </div>
    );
  }
}

LocationInput.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
};

LocationInput.defaultProps = {
  className: "",
  inputClassName: "",
  defaultValue: "",
  disabled: false,
  placeholder: "",
  value: undefined,
  onBlur: () => null,
  onFocus: () => null,
  onChange: () => null,
  onInputChange: () => null,
};
