export default class LocationSearch {
  config = {
    country: "UK",
  };

  constructor(config = {}) {
    const { google } = window;
    if (!google) {
      throw new Error(
        "Trying to use LocationSearch without Google Maps API initialized",
      );
    }

    this.config = Object.assign(this.config, config);
    this.Geocoder = new google.maps.Geocoder();
    this.AutocompleteService = new google.maps.places.AutocompleteService();
  }

  search(query = "") {
    const { google } = window;
    return new Promise((resolve, reject) => {
      if (query) {
        const requestConfig = {
          input: query,
          componentRestrictions: { country: this.config.country },
          types: ["geocode"],
        };
        this.AutocompleteService.getPlacePredictions(
          requestConfig,
          (predictions, status) => {
            if (
              status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
            ) {
              resolve([]);
              return;
            }
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              reject(status);
              return;
            }

            if (!Array.isArray(predictions)) {
              resolve([]);
            }

            const suggestions = [];
            for (const prediction of predictions) {
              if (prediction.place_id) {
                suggestions.push({
                  displayAddress: prediction.description,
                  placeID: prediction.place_id,
                  types: prediction.types,
                });
              }
            }
            resolve(suggestions);
          },
        );
      } else {
        resolve([]);
      }
    });
  }

  getDetails(placeID = null) {
    return new Promise((resolve, reject) => {
      if (!placeID) {
        reject("No place ID provided.");
      }
      this.Geocoder.geocode({ placeId: placeID }, (results, status) => {
        if (status === "OK") {
          const place = results[0];
          resolve({
            displayAddress: place.formatted_address,
            addressComponents: place.address_components,
            location: {
              lng: place.geometry.location.lng(),
              lat: place.geometry.location.lat(),
            },
            placeID,
          });
        } else {
          throw new Error(
            `There was an error getting location details: ${status}`,
          );
        }
      });
    });
  }
}
