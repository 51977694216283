import React from "react";
import PropTypes from "prop-types";
import Svg from "components/elements/svg";
import isParent from "helpers/is-parent";

export default class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      selected: [],
      activeOption: -1,
    };
    this.optionsListItems = [];
  }

  UNSAFE_componentWillMount() {
    const { defaultValue, value, options } = this.props;

    if (value) {
      value.forEach((item, index) => {
        if (!options.find((option) => option.value === item)) {
          value.splice(index, 1);
          console.error(`Given value '${item}' is missing in options prop`);
          console.error("Available options:", options);
        }
      });

      if (value) {
        this.setState({
          selected: value,
        });
      }
    } else if (defaultValue) {
      defaultValue.forEach((item, index) => {
        if (!item) {
          defaultValue.splice(index, 1);
          return;
        }
        if (!options.find((option) => option.value === item)) {
          defaultValue.splice(index, 1);
          console.error(
            `Given default value '${item}' is missing in options prop`,
          );
          console.error("Available options:", options);
        }
      });

      if (defaultValue) {
        this.setState({
          selected: defaultValue,
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps({ value, options }) {
    if (value) {
      value.forEach((item, index) => {
        if (!options.find((option) => option.value === item)) {
          value.splice(index, 1);
          console.error(`Given value '${item}' is missing in options prop`);
          console.error("Available options:", options);
        }
      });

      if (value) {
        this.setState({
          selected: value,
        });
      }
    }
  }

  getDisplayValue() {
    if (this.state.selected.length === 0 && this.props.placeholder) {
      return this.props.placeholder;
    }
    if (!this.props.multiple) {
      return this.props.options.find((option) =>
        this.state.selected.includes(option.value),
      ).label;
    }
    if (this.props.multiple) {
      return this.state.selected
        .map(
          (value) =>
            this.props.options.find((option) => value === option.value).label,
        )
        .join(", ");
    }
    return "";
  }

  handleFocus() {
    if (!this.props.disabled) {
      this.setState({
        active: true,
      });
    }
  }

  handleBlur() {
    setTimeout(() => {
      const element = document.activeElement;
      let isOutside = true;

      if (element === this.dropdown) {
        isOutside = false;
      }

      if (isOutside) {
        isOutside = !isParent(element, this.dropdown);
      }

      if (isOutside) {
        this.setState({
          active: false,
          activeOption: -1,
        });
      }
    }, 1); // timeout necessary to allow the new focused element to gain focus
  }

  handleCloseClick() {
    this.setState({ active: false, activeOption: -1 });
  }

  handleKeyDown(e) {
    switch (e.which) {
      case 13: {
        // enter
        e.preventDefault();
        if (this.state.activeOption >= 0) {
          this.selectOption(this.props.options[this.state.activeOption].value);
        } else if (!this.props.multiple) {
          this.dropdown.blur();
        }
        break;
      }
      case 38: {
        // arrow up
        e.preventDefault();
        let activeOption = this.state.activeOption - 1;

        if (this.state.activeOption === -1 || this.state.activeOption === 0) {
          activeOption = this.props.options.length - 1;
        }
        this.setState({ activeOption }, () => {
          // scroll new active element into view
          const list = this.optionsList;
          const items = this.optionsListItems;
          if (activeOption === -1 || activeOption === 0) {
            this.optionsList.scrollTop = 0;
          } else if (items[activeOption].offsetTop < list.scrollTop) {
            this.optionsList.scrollTop = items[activeOption].offsetTop;
          } else if (items[activeOption].offsetTop > list.offsetHeight) {
            this.optionsList.scrollTop = items[activeOption].offsetTop;
          }
        });
        break;
      }
      case 40: {
        // arrow down
        e.preventDefault();
        let activeOption = this.state.activeOption + 1;
        if (this.state.activeOption === this.props.options.length - 1) {
          activeOption = 0;
        }

        this.setState({ activeOption }, () => {
          // scroll new active element into view
          this.optionsList.scrollTop =
            activeOption === -1 || activeOption === 0
              ? 0
              : this.optionsListItems[activeOption].offsetTop;
        });
        break;
      }
      case 27: {
        this.setState({ active: false });
        this.dropdown.blur();
        break;
      }
      default: {
        break;
      }
    }
  }

  selectOption(value) {
    let newValue = [];
    if (this.state.selected.includes(value) && this.props.multiple) {
      const index = this.state.selected.indexOf(value);
      newValue = [...this.state.selected];
      newValue.splice(index, 1);
    } else if (this.props.multiple) {
      newValue = [...this.state.selected];
      newValue.push(value);
    } else {
      newValue = [value];
    }

    if (this.props.multiple) {
      this.setState({ selected: newValue }, () =>
        this.props.onChange(newValue),
      );
    } else {
      this.setState(
        {
          selected: newValue,
          active: false,
          activeOption: -1,
        },
        () => this.props.onChange(newValue),
      );
    }
  }

  render() {
    this.optionsListItems = [];
    const { options, disabled, ...props } = this.props;
    const displayValue = this.getDisplayValue();
    const classNames = ["dropdown", props.className];

    if (this.state.active) {
      classNames.push("active");
    }
    if (this.props.disabled) {
      classNames.push("disabled");
    }
    if (displayValue === this.props.placeholder) {
      classNames.push("placeholder");
    }

    return (
      <div
        role="menu"
        tabIndex={disabled ? "-1" : "0"}
        className={classNames.join(" ")}
        onFocus={(e) => this.handleFocus(e)}
        onBlur={(e) => this.handleBlur(e)}
        onKeyDown={(e) => this.handleKeyDown(e)}
        ref={(domNode) => {
          this.dropdown = domNode;
        }}
      >
        <span className="dropdown__value" role="presentation">
          <span className="dropdown__value-label">{displayValue}</span>
          <Svg className="dropdown__value-icon" src="angle-down" />
          <span
            role="button"
            tabIndex={this.state.active ? "0" : "-1"}
            className="dropdown__value__close"
            onClick={() => this.handleCloseClick()}
          />
        </span>
        <ul
          className="dropdown__options"
          ref={(domNode) => {
            this.optionsList = domNode;
          }}
        >
          {options.length > 0 &&
            options.map((option, optionIndex) => {
              const optionClassNames = ["dropdown__option"];

              if (this.state.selected.includes(option.value)) {
                optionClassNames.push("selected");
              }
              if (this.state.activeOption === optionIndex) {
                optionClassNames.push("active");
              }

              return (
                <li
                  className={optionClassNames.join(" ")}
                  key={option.value}
                  tabIndex="0"
                  role="menuitem"
                  onFocus={() => this.setState({ activeOption: optionIndex })}
                  onClick={() => this.selectOption(option.value)}
                  ref={(domNode) => {
                    this.optionsListItems[optionIndex] = domNode;
                  }}
                >
                  <span className="label">{option.label}</span>
                  <Svg src="check-in-circle" className="icon" />
                </li>
              );
            })}
          {options.length === 0 && (
            <li className="dropdown__option no-options">
              No choices available.
            </li>
          )}
        </ul>
      </div>
    );
  }
}

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.node,
    }),
  ),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  multiple: PropTypes.bool,
  placeholder: PropTypes.node,
  defaultValue: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  onChange: PropTypes.func.isRequired,
};

Dropdown.defaultProps = {
  options: [],
  disabled: false,
  className: "",
  multiple: false,
  placeholder: "Select an option",
  defaultValue: null,
  value: null,
};
