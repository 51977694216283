const isParent = (element, potentialParent) => {
  let child = element;

  while (child.parentElement) {
    child = child.parentElement;
    if (child === potentialParent) {
      return true;
    }
  }

  return false;
};

export default isParent;
